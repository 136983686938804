<!-- Page Header Start -->
<div class="container-fluid bg-appointment headerPageTest1">
    <div class="container">
        <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
            <h3 class="display-3 text-white text-uppercase">Blog</h3>
            <div class="d-inline-flex text-white">
                <p class="m-0 text-uppercase"><a class="text-white" href="/">Inicio</a></p>
                <i class="fa fa-angle-double-right pt-1 px-3"></i>
                <p class="m-0 text-uppercase">Blog</p>
            </div>
        </div>
    </div>
</div>
<!-- Page Header End -->

<div class="container-fluid py-5">   



<div *ngIf="noticia" class="container mt-4">
    <div class="text-start pb-2">
    <h1>{{ noticia.Titulo }}</h1>
    <h4 class="text-muted">{{ noticia.Subtitulo }}</h4>
</div>
    <p><strong>Fecha:</strong> {{ noticia.Fecha }}</p>
    <p><strong>Sección:</strong> {{ noticia.Apartado }}</p>
    <img [src]="'assets/img/' + noticia.Imagen" class="img-fluid mb-4" alt="{{ noticia.Titulo }}">
    <p>{{ noticia.ContenidoLargo }}</p>
  </div>
</div>
