import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BloginatorService } from '../bloginator.service';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-blog-details',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './blog-details.component.html',
  styleUrl: './blog-details.component.css'
})
export class BlogDetailsComponent {
  noticia: any;

  constructor(private route: ActivatedRoute, private bloginatorService: BloginatorService) {}

  ngOnInit(): void {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    this.bloginatorService.getNews().subscribe(noticias => {
      this.noticia = noticias.find(noticia => noticia.Id === id);
    });
  }
}