import { Component } from '@angular/core';
import { CommonModule } from '@angular/common'; // Importa CommonModule
import { HttpClientModule } from '@angular/common/http'; // Importa HttpClientModule
import { BloginatorService } from '../bloginator.service'; // Importa el servicio
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-blog',
  standalone: true, // Este es un componente standalone
  imports: [CommonModule, HttpClientModule, RouterModule], // Asegúrate de incluir CommonModule
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent {
  noticias: any[] = [];

  constructor(private bloginatorService: BloginatorService) {}

  ngOnInit(): void {
    this.bloginatorService.getNews().subscribe(data => {
      this.noticias = data;
    });
  }
}