import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { FirmaComponent } from './firma/firma.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { BlogComponent } from './blog/blog.component';
import { ContactoComponent } from './contacto/contacto.component';
import { AvisolegalComponent } from './avisolegal/avisolegal.component';
import { PrivacidadComponent } from './privacidad/privacidad.component';
import { CookiesComponent } from './cookies/cookies.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';

const routeConfig: Routes = [
  {
    path: '',
    component: HomeComponent,
    title: 'Despacho de Abogados en Sabadell | Rodríguez Rovira Advocats'
  },
  {
    path: 'firma',
    component: FirmaComponent,
    title: 'La Firma | Rodríguez Rovira Advocats'
  },
  {
    path: 'servicios',
    component: ServiciosComponent,
    title: 'Servicios | Rodríguez Rovira Advocats'
  },
  {
    path: 'servicios/despidos',
    component: ServiciosComponent,
    title: 'Servicios | Rodríguez Rovira Advocats'
  },
  {
    path: 'servicios/nacionalidad-extranjeria',
    component: ServiciosComponent,
    title: 'Servicios | Rodríguez Rovira Advocats'
  },
  {
    path: 'servicios/seguridad-social',
    component: ServiciosComponent,
    title: 'Servicios | Rodríguez Rovira Advocats'
  },
  {
    path: 'servicios/familia',
    component: ServiciosComponent,
    title: 'Servicios | Rodríguez Rovira Advocats'
  },
  {
    path: 'servicios/inmobiliario',
    component: ServiciosComponent,
    title: 'Servicios | Rodríguez Rovira Advocats'
  },
  {
    path: 'servicios/accidentes-trafico',
    component: ServiciosComponent,
    title: 'Servicios | Rodríguez Rovira Advocats'
  },
  {
    path: 'blog',
    component: BlogComponent,
    title: 'Blog | Rodríguez Rovira Advocats'
  },
  {
    path: 'blog/:id',
    component: BlogDetailsComponent,
    title: 'Blog | Rodríguez Rovira Advocats'
  },

  {
    path: 'contacto',
    component: ContactoComponent,
    title: 'Contacto | Rodríguez Rovira Advocats'
  },
  {
    path: 'aviso-legal',
    component: AvisolegalComponent,
    title: 'Aviso Legal | Rodríguez Rovira Advocats'
  },
  {
    path: 'privacidad',
    component: PrivacidadComponent,
    title: 'Política de Privacidad | Rodríguez Rovira Advocats'
  },
  {
    path: 'cookies',
    component: CookiesComponent,
    title: 'Política de Cookies | Rodríguez Rovira Advocats'
  },



  { path: '**', component: NotfoundComponent, title: '404 (Página no encontrada) | Rodríguez Rovira Advocats' }
];

export default routeConfig;