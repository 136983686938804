import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class BloginatorService {

  private basePath = 'assets/bloginator/';
  private defaultLang = 'es';
  private lang: string;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    // Determina el idioma seleccionado
    if (isPlatformBrowser(this.platformId)) {
      const savedLang = localStorage.getItem('lang');
      this.lang = savedLang || this.defaultLang;
    } else {
      this.lang = this.defaultLang; // Idioma por defecto en caso de no estar en el navegador
    }
  }

  getNews(): Observable<any[]> {
    const timestamp = new Date().getTime(); // Genera un timestamp único
    const jsonUrl = `${this.basePath}${this.lang}-blog.json?ts=${timestamp}`;
    return this.http.get<any[]>(jsonUrl);
  }
}
